import React, { Fragment, Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { SingleSelect } from "react-select-material-ui"

import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  injectIntl,
  FormattedMessage,
  IntlContextConsumer,
  useIntl,
  changeLocale,
  Link,
} from "gatsby-plugin-react-intl"

import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./style"

const NAV_ITEMS = ["Product", "Pricing", "Contact"]

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuOpen: false,
      hasScrolled: false,
      language: this.props.intl.locale,
    }
  }

  customStyles = {
    clearIndicator: (prevStyle, state) => ({
      ...prevStyle,
      display: "none",
    }),
  }

  options = ["SV", "EN"]
  languageName = {
    en: "English",
    se: "Svenska",
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  handleLanguageChange = lang => {
    changeLocale(lang.toLowerCase())
    this.setState({ language: lang })
  }

  getNavAnchorLink = item => (
    <AnchorLink
      to={`/${this.props.intl.locale}/#${item.toLowerCase()}`}
      onClick={this.closeMobileMenu}
    >
      {item &&
        this.props.intl.formatMessage({
          id: `navigation_${item.toLowerCase()}`,
          defaultMessage: item,
        })}
    </AnchorLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
        <NavItem
          key={"book_demo"}
          onClick={e => {
            e.preventDefault()
            window.open("https://calendly.com/buildsight/15min")
          }}
        >
          {this.props.intl.formatMessage({
            id: "navigation_demo",
            defaultMessage: "Book demo",
          })}
        </NavItem>
        <NavItem key={"blog"}>
          <Link to="/blog">
            {this.props.intl.formatMessage({
              id: "navigation_blog",
              defaultMessage: "Blog",
            })}
          </Link>
        </NavItem>
        {mobile && (
          <Fragment>
            <br></br>
            <NavItem style={{ minWidth: "60px" }}>
              <a href="https://app.buildsight.se/login">
                <FormattedMessage id="login" defaultMessage="Login" />
              </a>
            </NavItem>
            <NavItem style={{ minWidth: "60px" }}>
              <a href="https://app.buildsight.se/signup">
                <FormattedMessage id="signup" defaultMessage="Sign up" />
              </a>
            </NavItem>
          </Fragment>
        )}

        {/* <NavItem key={"about"}>
          <Link to="/about">
          {this.props.intl.formatMessage({
          id: "navigation_about",
          defaultMessage: "About"
        })}
          </Link>
        </NavItem> */}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <AnchorLink
                to={`/${this.props.intl.locale}/#top`}
                onClick={this.closeMobileMenu}
              >
                <StaticQuery
                  query={graphql`
                    query {
                      file(
                        sourceInstanceName: { eq: "images" }
                        name: { eq: "logo" }
                      ) {
                        childImageSharp {
                          fixed(width: 160) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <Img fixed={data.file.childImageSharp.fixed} />
                  )}
                />
              </AnchorLink>
            </Scrollspy>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
          <ActionsContainer>
            {/*<LanguageSwitcher lang="sv" />*/}
            <IntlContextConsumer>
              {({ languages, language: currentLocale }) => (
                <SingleSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={this.customStyles}
                  value={currentLocale.toUpperCase()}
                  options={this.options}
                  onChange={this.handleLanguageChange}
                />
              )}
            </IntlContextConsumer>

            <NavItem style={{ minWidth: "60px" }}>
              <a href="https://app.buildsight.se/login">
                <FormattedMessage id="login" defaultMessage="Login" />
              </a>
            </NavItem>
            <button
              onClick={e => {
                e.preventDefault()
                window.location.href = "https://app.buildsight.se/signup"
              }}
            >
              <FormattedMessage
                id="navigation_get_started_button"
                defaultMessage="Get started"
              />
            </button>
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}

export default injectIntl(Navigation)
